import { useState } from "react";
import { exploreImg, rightArrow } from "../../../assets";
import { exploreBtnTxt } from "../../../utils/static-datas/explore-btn";

const ExploreOur = () => {

  const [activeBtn , setActiveBtn] = useState(0);

  const handleActive = (i) =>{
    setActiveBtn(i);
  }

  return (
    <>
      <div className="max-w-[75.25rem] my-[3rem] text-center mx-auto">
        <div className="max-w-[71.313rem] my-[3rem] text-center mx-auto">
          <h1 className="text-[32px] xs:text-[16px] font-[900] ruda pb-[1.5rem]">
            Explore Our Cutting-Edge Courses
          </h1>
        </div>
        <div className="flex justify-between gap-3  !xl:no-scrollbar overflow-x-auto">
          {exploreBtnTxt.map((each, i) => {
            return (
              <div
                onClick={() => handleActive(i)}
                key={i}
                className={`px-[1.5rem] bg-[#ecb32c] cursor-pointer bg-opacity-[20%] border rounded-[10px] ${
                  activeBtn === i && "border-[#ecb32c] "
                }`}
              >
                <h1
                  className={`py-[.8rem] xs:text-[10px] sm:text-[13px]  font-[600] ${
                    activeBtn === i && "!text-[#ecb32c] "
                  }`}
                >
                  {each}
                </h1>
              </div>
            );
          })}
        </div>

        {activeBtn === 0 && (
          <div className="flex xs:block sm:block md:block w-full text-start  md:mx-auto sm:mx-auto md:text-center sm:text-center xs:text-center justify-between items-center">
            <div>
              <h1 className="text-[24px] xs:text-[17px]  sm:text-center xs:text-center font-[600] md:pt-[1rem] xs:py-[1rem] sm:py-[1rem]">
                Software Engineering
              </h1>
              <p className="max-w-[29.125rem] py-[2rem] sm:mx-auto md:mx-auto ">
                Software Engineering is a systematic approach to the design,
                development, testing, and maintenance of software applications.
                It combines principles from computer science, project
                management, and engineering to create high-quality software that
                meets user needs and industry standards.
              </p>
              <p>6-9 Months</p>
              <p className="py-[2rem]">Beginner Friendly</p>
              <div className="flex items-center md:justify-center sm:justify-center xs:justify-center gap-2 text-[#cf375d]">
                <a
                  href="/software-engineering"
                  className="text-[18px] font-[600]"
                >
                  Learn more
                </a>{" "}
                <div>
                  <img
                    src={rightArrow}
                    className="md:mx-auto place-self-center"
                    alt=""
                  />
                </div>{" "}
              </div>
            </div>
            <div className="md:mx-auto md:my-[1.5rem] md:w-fit">
              <img src={exploreImg} alt="" />
            </div>
          </div>
        )}
        {activeBtn === 1 && (
          <div className="flex xs:block sm:block md:block w-full text-start md:text-center sm:text-center xs:text-center justify-between items-center">
            <div>
              <h1 className="text-[24px] xs:text-[17px] md:text-center sm:text-center xs:text-center font-[600] md:pt-[1rem] xs:py-[1rem] sm:py-[1rem]">
                Artificial Intelligence
              </h1>
              <p className="max-w-[29.125rem] py-[2rem] sm:mx-auto md:mx-auto md:text-center">
                Artificial Intelligence (AI) is a branch of computer science
                focused on building systems that can perform tasks typically
                requiring human intelligence. These tasks include
                problem-solving, learning, reasoning, language understanding,
                and perception. AI combines data, algorithms, and computational
                power to create models that mimic human thinking and
                decision-making processes, enabling applications like chatbots,
                recommendation systems, autonomous vehicles, and predictive
                analytics. The goal is to develop intelligent systems that adapt
                and improve over time, enhancing efficiency and solving complex
                real-world problems across industries.
              </p>
              <p>6 Months</p>
              <p className="py-[2rem]">Beginner Friendly</p>
              <div className="flex items-center md:justify-center sm:justify-center xs:justify-center gap-2 text-[#cf375d]">
                <p className="text-[18px] font-[600]">Learn more</p>{" "}
                <div>
                  <img
                    src={rightArrow}
                    className="md:mx-auto place-self-center"
                    alt=""
                  />
                </div>{" "}
              </div>
            </div>
            <div className="md:mx-auto md:w-fit">
              <img src={exploreImg} alt="" />
            </div>
          </div>
        )}
        {activeBtn === 2 && (
          <div className="flex xs:block sm:block md:block w-full text-start md:text-center sm:text-center xs:text-center justify-between items-center">
            <div>
              <h1 className="text-[24px] xs:text-[17px] md:text-center sm:text-center xs:text-center font-[600] md:pt-[1rem] xs:py-[1rem] sm:py-[1rem]">
                Cyber Security
              </h1>
              <p className="max-w-[29.125rem] py-[2rem] sm:mx-auto md:mx-auto md:text-center">
                Cybersecurity is the practice of protecting systems, networks,
                and data from cyber threats such as unauthorized access,
                attacks, or damage. It involves implementing technologies,
                processes, and controls to safeguard digital assets and ensure
                confidentiality, integrity, and availability of information.
                Cybersecurity encompasses areas like network security,
                application security, data encryption, threat detection, and
                incident response. Its goal is to defend against cybercriminals,
                malware, ransomware, and other malicious activities, ensuring a
                secure digital environment for individuals and organizations in
                an increasingly interconnected world.
              </p>
              <p>4 Months</p>
              <p className="py-[2rem]">Beginner Frienly</p>
              <div className="flex items-center md:justify-center sm:justify-center xs:justify-center gap-2 text-[#cf375d]">
                <a href="/cyber-security" className="text-[18px] font-[600]">
                  Learn more
                </a>{" "}
                <div>
                  <img
                    src={rightArrow}
                    className="md:mx-auto place-self-center"
                    alt=""
                  />
                </div>{" "}
              </div>
            </div>
            <div className="md:mx-auto md:w-fit">
              <img src={exploreImg} alt="" />
            </div>
          </div>
        )}
        {activeBtn === 3 && (
          <div className="flex xs:block sm:block md:block w-full text-start md:text-center sm:text-center xs:text-center justify-between items-center">
            <div>
              <h1 className="text-[24px] xs:text-[17px] md:text-center sm:text-center xs:text-center font-[600] md:pt-[1rem] xs:py-[1rem] sm:py-[1rem]">
                Data Engineeirng
              </h1>
              <p className="max-w-[29.125rem] py-[2rem] sm:mx-auto md:mx-auto md:text-center">
              Data Engineering is a specialized field that focuses on designing, building, and 
              maintaining the infrastructure and systems required for efficient data collection, 
              storage, and processing. It involves expertise in database management, big data technologies, 
              cloud computing, and data pipelines to ensure seamless data flow and accessibility.
              Data engineers work with tools like Apache Spark, Hadoop, SQL, and ETL (Extract, Transform, Load) 
              processes to optimize data architecture for analytics and machine learning applications. 
              The goal of data engineering is to enable organizations to manage large-scale data efficiently,
              support data-driven decision-making, and enhance business intelligence across industries such as
              finance, healthcare, e-commerce, and technology.
                {/* Data Science is an interdisciplinary field that uses scientific
                methods, algorithms, and systems to extract meaningful insights
                and knowledge from structured and unstructured data. It combines
                expertise in mathematics, statistics, programming, and domain
                knowledge to analyze and interpret complex data. Data scientists
                utilize tools like machine learning, data visualization, and
                predictive modeling to uncover patterns, forecast trends, and
                make data-driven decisions. The goal of data science is to solve
                real-world problems, optimize processes, and drive innovation
                across various industries, including healthcare, finance,
                marketing, and technology. */}
              </p>
              <p>4 Months</p>
              <p className="py-[2rem]">Beginner</p>
              <div className="flex items-center md:justify-center sm:justify-center xs:justify-center gap-2 text-[#cf375d]">
                <a href="/data-science" className="text-[18px] font-[600]">
                  Learn more
                </a>{" "}
                <div>
                  <img
                    src={rightArrow}
                    className="md:mx-auto place-self-center"
                    alt=""
                  />
                </div>{" "}
              </div>
            </div>
            <div className="md:mx-auto md:w-fit">
              <img src={exploreImg} alt="" />
            </div>
          </div>
        )}
        {activeBtn === 4 && (
          <div className="flex xs:block sm:block md:block w-full text-start md:text-center sm:text-center xs:text-center justify-between items-center">
            <div>
              <h1 className="text-[24px] xs:text-[17px] md:text-center sm:text-center xs:text-center font-[600] md:pt-[1rem] xs:py-[1rem] sm:py-[1rem]">
                DevOps
              </h1>
              <p className="max-w-[29.125rem] py-[2rem] sm:mx-auto md:mx-auto md:text-center">
                DevOps is a collaborative approach that integrates software
                development (Dev) and IT operations (Ops) to streamline the
                software delivery lifecycle. It focuses on automating workflows,
                fostering collaboration, and improving the speed, reliability,
                and quality of software releases. DevOps practices include
                continuous integration, continuous delivery (CI/CD),
                infrastructure as code (IaC), monitoring, and incident
                management. By utilizing tools like Docker, Kubernetes, Jenkins,
                and cloud platforms, DevOps enables organizations to deliver
                software more efficiently, respond quickly to market demands,
                and maintain high system reliability in dynamic environments.
              </p>
              <p>4 Months</p>
              <p className="py-[2rem]">Beginner Friendly</p>
              <div className="flex items-center md:justify-center sm:justify-center xs:justify-center gap-2 text-[#cf375d]">
                <a href="/devops" className="text-[18px] font-[600]">
                  Learn more
                </a>{" "}
                <div>
                  <img
                    src={rightArrow}
                    className="md:mx-auto place-self-center"
                    alt=""
                  />
                </div>{" "}
              </div>
            </div>
            <div className="md:mx-auto md:w-fit">
              <img src={exploreImg} alt="" />
            </div>
          </div>
        )}
        {activeBtn === 5 && (
          <div className="flex xs:block sm:block md:block w-full text-start md:text-center sm:text-center xs:text-center justify-between items-center">
            <div>
              <h1 className="text-[24px] xs:text-[17px] md:text-center sm:text-center xs:text-center font-[600] md:pt-[1rem] xs:py-[1rem] sm:py-[1rem]">
                Web3 Technology
              </h1>
              <p className="max-w-[29.125rem] py-[2rem] sm:mx-auto md:mx-auto md:text-center">
                Web3 technology represents the next evolution of the internet,
                emphasizing decentralization, user ownership, and enhanced
                security through blockchain technology. Unlike the traditional
                Web2 model, where platforms and data are controlled by
                centralized entities, Web3 empowers users to own their data and
                participate directly in online ecosystems. It integrates
                concepts like decentralized finance (DeFi), non-fungible tokens
                (NFTs), smart contracts, and decentralized applications (dApps).
                Web3 enables peer-to-peer interactions without intermediaries,
                fostering transparency and trust. Its applications span
                industries, including finance, gaming, supply chain, and digital
                identity, offering innovative solutions that redefine how
                individuals and organizations interact online.
              </p>
              <p>6 Months</p>
              <p className="py-[2rem]">Beginner</p>
              <div className="flex items-center md:justify-center sm:justify-center xs:justify-center gap-2 text-[#cf375d]">
                <a href="/web3-technology" className="text-[18px] font-[600]">
                  Learn more
                </a>{" "}
                <div>
                  <img
                    src={rightArrow}
                    className="md:mx-auto place-self-center"
                    alt=""
                  />
                </div>{" "}
              </div>
            </div>
            <div className="md:mx-auto md:w-fit">
              <img src={exploreImg} alt="" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ExploreOur;
