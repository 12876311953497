import { courseDescription, curriculumOverview, keySkill } from "../../../assets";
import Button from "../../../components/button/button";
import { Link } from "react-router-dom";

const CourseDescription = () =>{
    return (
      <>
        <div className="mt-[4rem] xs:text-center sm:text-center md:text-center lg:text-center xs:mx-auto">
          <div className="">
            {/* <img src={courseDescription} alt="" /> */}
            <h1 className="text-[24px] font-[900] ruda">
              Course Description: Data Engineering Bootcamp
            </h1>
          </div>

          <div className="max-w-[44.375rem]   text-[20px]">
            <h1 className=" font-[700] mt-[2rem]">
              What Does a Data Engineers Do?
            </h1><br />

            <p className="font-[300]">
              {/* Data scientists turn raw data into actionable insights. They
              design and implement algorithms, build predictive models, and
              communicate findings that drive decision-making. Working at the
              intersection of statistics, programming, and domain expertise,
              data scientists use cutting-edge tools and techniques to uncover
              trends, automate processes, and solve complex problems. As a data
              scientist, you will: */}
              Data engineers build and maintain the infrastructure that enables organizations to collect, 
              store, and process vast amounts of data efficiently. They design scalable data pipelines, 
              optimize databases, and ensure seamless data flow for analytics and machine learning applications. 
              Working at the intersection of software engineering, data architecture, and cloud technologies,
              data engineers create robust systems that support data-driven decision-making.
              As a data engineer, you will:
            </p>
          </div>

          <ul className="max-w-[45.625rem] my-[2rem] xs:text-[15px] sm:text-[18px] text-[20px] font-[300] pl-[2.2rem] list-disc">
            <li>
              {/* Analyze and preprocess large datasets to ensure data quality.{" "} */}
              Design and develop data pipelines to collect, process, and transfer data between systems efficiently.
            </li>
            <li>
              {/* Build predictive and descriptive models using machine learning
              algorithms. */}
              Build and maintain databases and data warehouses to store structured and unstructured data securely.

            </li>
            <li>
              {/* Visualize data to communicate insights effectively to
              stakeholders. */}
              Optimize data architectures and workflows for performance, scalability, and reliability.
              
            </li>
            <li>
              {/* Deploy machine learning models into production environments. */}
              Implement ETL (Extract, Transform, Load) processes to clean, transform, and integrate data from multiple sources.
            </li>
            <li>
              {/* Use statistical techniques to validate hypotheses and guide
              business decisions. */}
              Work with big data technologies like Apache Spark, Hadoop, and Kafka to handle large-scale data processing.

            </li>
            <li>
              {/* Work with big data technologies like Hadoop and Spark. */}
              Leverage cloud platforms such as AWS, Google Cloud, and Azure for scalable data storage and computing.

            </li>
            <li>
              {/* Collaborate with cross-functional teams to solve complex problems. */}
              Ensure data quality and integrity by implementing validation, monitoring, and governance processes.
            </li>
            <li>
            Collaborate with data scientists and analysts to provide clean, reliable data for analytics and machine learning.
            </li>
            <li>
            Automate data workflows and processes to improve efficiency and reduce manual effort.
            </li>
            <li>
            Ensure data security and compliance by following best practices and industry regulations.
            </li>
          </ul>

          <div className="max-w-[44.5rem] my-[1rem] xs:text-[15px] sm:text-[18px] text-[20px]">
            <h1 className="font-[700]">
              What Will You Learn in our Data science Bootcamp?
            </h1>
            <p className="font-[300]">
              {/* Our data science bootcamp provides hands-on experience with the
              entire data science pipeline, from data collection to model
              deployment. You’ll gain proficiency in tools like Python, R, SQL,
              and cloud-based platforms, while mastering techniques in machine
              learning, data visualization, and big data processing. */}
              Our data engineering bootcamp provides hands-on experience with the entire data pipeline,
              from data ingestion to storage, processing, and automation. You’ll gain proficiency in tools 
              like SQL, Python, Apache Spark, and cloud platforms, while mastering techniques in database management, 
              ETL processes, big data technologies, and data pipeline optimization.
            </p>
          </div>

          <div className="">
            <h1 className="text-[24px] xs:text-[17px] sm:text-[19px] font-[900] ruda my-[1.5rem]">
              Curriculum Overview
            </h1>
          </div>
          <div className="max-w-[35.125rem] text-[20px] xs:text-[15px] sm:text-[18px] xs:mx-auto sm:mx-auto md:mx-auto">
            <div>
              <h1 className="font-[600]">
                {/* Data Exploration & Analysis: */}
                 Introduction to Data Engineering
              </h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  {/* Data Cleaning, Transformation, and Preprocessing */}
                  Overview of data engineering and its role in the data ecosystem
                </li>
                <li>
                  {/* Exploratory Data Analysis (EDA) using Pandas, NumPy, and
                  Matplotlib */}
                  Key differences between data engineers, data scientists, and data analysts
                </li>
                <li>
                  {/* Statistical Analysis and Hypothesis Testing */}
                  Understanding data architecture, pipelines, and ETL processes
                 </li>
                 <li>
                 Introduction to cloud computing and big data technologies
                 </li>
              </ul>
            </div>

            <div>
              <h1 className="font-[600]">
                {/* Programming for Data Science: */}
                Python and SQL for Data Engineering
                </h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  {/* Python Programming for Data Analysis */}
                  Python programming for data engineering (Pandas, NumPy)

                </li>
                <li>
                  {/* R for Statistical Computing */}
                  SQL for data querying, manipulation, and optimization
                </li>
                <li>
                  {/* SQL for Data Retrieval and Management */}
                  Advanced SQL concepts (Joins, Indexing, Stored Procedures, Window Functions)
                  </li>
                <li>Hands-on projects with relational databases (PostgreSQL, MySQL)</li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">
                {/* Machine Learning & AI: */}
                Data Storage & Management:
              </h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  {/* Supervised Learning (Regression, Classification) */}
                  Introduction to databases: Relational (SQL) vs. NoSQL databases

                </li>
                <li>
                  {/* Unsupervised Learning (Clustering, Dimensionality Reduction){" "} */}
                  Designing efficient database schemas and indexing strategies
                </li>
                <li>
                  {/* Neural Networks and Deep Learning Basics */}
                  Working with NoSQL databases (MongoDB, Cassandra)

                  </li>
                <li>
                Introduction to data lakes and data warehouses (Amazon Redshift, Google BigQuery, Snowflake)
                  </li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">
                {/* Data Visualization: */}
                ETL (Extract, Transform, Load) & Data Pipelines
                </h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  {/* Creating Dashboards and Reports with Tableau and Power BI */}
                  ETL process: Extracting data from multiple sources
                </li>
                <li>
                  {/* Advanced Data Visualization in Python (Seaborn, Plotly) */}
                  Data transformation techniques and best practices

                  </li>
                <li>
                  {/* Storytelling with Data for Non-Technical Audiences */}
                  Building automated ETL pipelines with Apache Airflow, Luigi
                  </li>
                <li>
                Handling real-time vs batch data processing
                </li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">Big Data & Cloud Computing:</h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  Introduction to Big Data Frameworks (Hadoop, Apache Spark)
                </li>
                <li>Distributed Computing and Data Pipeline Design</li>
                <li>
                  Cloud-Based Machine Learning with AWS, Azure, and Google Cloud
                </li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">
                {/* Deployment & Production: */}
                Big Data Technologies & Tools
                </h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  {/* Model Deployment with Flask, Django, and FastAPI  */}
                  Introduction to distributed computing and parallel processing
                  </li>
                <li>
                  {/* Monitoring and Updating Models in Production */}
                  Working with Apache Hadoop and Apache Spark
                </li>
                <li>
                  {/* Building End-to-End Data Science Pipelines */}
                  Data streaming with Apache Kafka and Apache Flink
                  </li>
                  <li>
                  Handling large-scale data processing challenges
                  </li>
              </ul>
            </div>
            <div>
              <h1 className="font-[600]">
                {/* Specialized Topics: */}
                Cloud Computing & Data Engineering on the Cloud

                </h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  {/* Natural Language Processing (NLP) */}
                  Cloud platforms overview (AWS, Google Cloud, Azure)

                   </li>
                <li>
                  {/* Time Series Analysis and Forecasting  */}
                  Serverless computing for data engineering (AWS Lambda, Google Cloud Functions)
                  </li>
                <li>
                  {/* Data Ethics and Responsible AI */}
                  Setting up cloud-based data pipelines with AWS Glue, Google Dataflow
                  </li>
                  <li>
                  Hands-on cloud storage (S3, Azure Blob Storage, Google Cloud Storage)
                  </li>
              </ul>
            </div>
            
            <div>
              <h1 className="font-[600]">
                {/* Specialized Topics: */}
                Data Orchestration & Workflow Automation

                </h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  {/* Natural Language Processing (NLP) */}
                  Automating data workflows with Apache Airflow

                   </li>
                <li>
                  {/* Time Series Analysis and Forecasting  */}
                  Monitoring and logging for data pipelines
                  </li>
                <li>
                  {/* Data Ethics and Responsible AI */}
                  Debugging and optimizing data processes
                  </li>
                  <li>
                  Implementing CI/CD for data pipelines
                  </li>
              </ul>
            </div>
            

            <div>
              <h1 className="font-[600]">
                {/* Specialized Topics: */}
                Data Governance, Security & Compliance

                </h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  {/* Natural Language Processing (NLP) */}
                  Data quality assurance and integrity best practices


                   </li>
                <li>
                  {/* Time Series Analysis and Forecasting  */}
                  Implementing data security measures (encryption, access control)
                  </li>
                <li>
                  {/* Data Ethics and Responsible AI */}
                  Compliance with GDPR, CCPA, and industry regulations
                  </li>
                  <li>
                  Data lineage and metadata management
                  </li>
              </ul>
            </div>


            <div>
              <h1 className="font-[600]">
                {/* Specialized Topics: */}
                Capstone Project

                </h1>
              <ul className="list-disc font-[300] pl-[2.2rem]">
                <li>
                  {/* Natural Language Processing (NLP) */}
                  End-to-end data engineering project


                   </li>
                <li>
                  {/* Time Series Analysis and Forecasting  */}
                  Designing and implementing a scalable data pipeline
                  </li>
                <li>
                  {/* Data Ethics and Responsible AI */}
                  Integrating various tools and technologies covered in the course
                  </li>
                  <li>
                  Presentation and feedback from industry experts
                  </li>
              </ul>
            </div>

          </div>
        </div>

        <div className="xs:text-center sm:text-center md:text-center lg:text-center xs:mx-auto">
          <h1 className="text-[24px] xs:text-[17px] sm:text-[19px] font-[900] ruda my-[1.5rem]">
            Key Skills You Will Develop
          </h1>
        </div>
        <div className="text-[20px] xs:text-[15px] sm:text-[18px] font-[300]">
       
          <p className="py-[1rem] xs:text-center sm:text-center md:text-center lg:text-center xs:mx-auto">
          By the end of the bootcamp, you will have:<br />
          ✅ A strong foundation in data engineering principles and best practices<br />
          ✅ Hands-on experience with SQL, Python, Apache Spark, Kafka, and cloud platforms<br />
          ✅ The ability to design, build, and optimize data pipelines and architectures<br />
          ✅ A portfolio of projects to showcase to potential employers<br />
          ✅ Career support, resume review, and interview preparation for data engineering roles<br />
          </p>
        </div>
        <div className="max-w-[44.5rem] my-[1rem] xs:text-[15px] sm:text-[18px] text-[20px]">
          <h1 className="font-[700]">Cost of the Course:</h1>
          <br />
          <p className="font-[300]">
            <ul className="list-disc font-[300] pl-[2.2rem]">
              <li>
                Data Engineering : <span className="font-bold">#500,000</span>
              </li>
            </ul>
          </p>
        </div>
        <Link to="/get-started">
          <Button
            btnText={"Join Now"}
            btnClassName={
              "!text-[18px] !h-fit !w-fit px-4 xs:text-[13px] sm:text-[16px] !bg-[#ecb32c] mt-[2rem]"
            }
          />
        </Link>
        <br />
      </>
    );
};

export default CourseDescription;