import { arrowDown } from "../assets";

const navLinkDatas = [
  //   {
  //     name: "",
  //     url: "/",
  //     end: true,
  //   },
  {
    name: "About Us",
    url: "/about-us",
    end: true,
  },
  {
    name: "Courses",
    url: "/courses",
    icon: { arrowDown },
  },
  {
    name: "Z-Teens",
    url: "/z-teens",
    icon: "",
  },
  // {
  //   name: "Resources",
  //   url: "/resources",
  //   icon: { arrowDown },
  // },
  {
    name: "News",
    url: "/resources",
    icon: "",
  },
  {
    name: "Portfolios",
    url: "/project",
    icon: "",
  },
];


const navResourceDropDown = [
  {
    name: "News",
    url: "",
  },
  {
    name: "Blog",
    url: "",
  }
 
];
const navCoursesDropDown = [
  {
    name: "Software Engineering",
    url: "/software-engineering",
  },
  {
    name: "Artificial Intelligence",
    url: "/web-development",
  },
  {
    name: "Cyber Security",
    url: "/cyber-security",
  },
  {
    name: "Data Engineering",
    url: "/data-science",
  },
  // {
  //   name: "DevOps",
  //   url: "/devops",
  // },
  // {
  //   name: "Web3 Technology",
  //   url: "/web3-technology",
  // },
];

export { navLinkDatas, navResourceDropDown, navCoursesDropDown };